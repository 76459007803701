.componentBlue {
  color: var(--color-blue-300);
}

.componentGreen {
  color: var(--color-green-300);
}

.componentOrange {
  color: var(--color-yellow-300);
}

.componentTransparent {
  color: transparent;
}
